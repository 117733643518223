import { useCallback, useState, type ComponentPropsWithoutRef, type JSX } from "react"

import type { Citation } from "~/types/api/routes/entry"
import type { EmptyCallback } from "~/types/components/empty"

import ChevronIcon from "~/assets/icons/chevron.svg?react"

/**
 * A box with a triangle on the right side.
 * @returns A React component.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.4.0
 */
const Triangle = ({
	width,
	height,

	...props
}: ComponentPropsWithoutRef<"div"> & {
	width: number
	height: number
}): JSX.Element => (
	<div
		{...props}
		className="flex flex-row"
		style={{
			height: `${height.toString()}px`
		}}>
		<div
			className="h-full bg-purple-alt"
			style={{
				width: `${width.toString()}px`
			}}
		/>
		<div
			className="h-0 w-0 border-e-0 border-transparent border-s-purple-alt"
			style={{
				borderWidth: `${(height / 2).toString()}px`,
				borderRightWidth: 0
			}}
		/>
	</div>
)

/**
 * Citations/sources drop-down.
 * @returns A React component.
 * @author Jay Hunter <jh@yello.studio>
 * @since 4.4.0
 */
const Citations = ({
	citations,

	...props
}: ComponentPropsWithoutRef<"div"> & {
	citations: Citation[]
}): JSX.Element => {
	const [isExpanded, setIsExpanded] = useState<boolean>(false)

	const onIconClick = useCallback<EmptyCallback>(() => {
		setIsExpanded(isCurrentlyExpanded => !isCurrentlyExpanded)
	}, [])

	// Don't render if there are no citations
	if (citations.length <= 0) return <></>

	return (
		<div {...props} className="flex flex-col rounded-b-2xl rounded-t-3xl bg-control-background">
			<div className="flex flex-row items-center gap-x-2 overflow-hidden rounded-full bg-logo-purple">
				<Triangle width={42} height={34} />

				<div className="flex flex-1 flex-row items-center justify-between p-1.5 px-2">
					<p className="-mb-0.5 text-white">Sources</p>

					<div className="flex rounded-full p-1 hover:cursor-pointer hover:bg-control-hover/15">
						<ChevronIcon
							width={14}
							height={14}
							className={`aspect-square rounded-full fill-white transition-transform hover:fill-white/90 active:fill-white/80 ${isExpanded ? "-rotate-90" : "rotate-180"}`}
							onClick={onIconClick}
						/>
					</div>
				</div>
			</div>

			<div
				className={`overflow-hidden px-1 transition-all ${isExpanded ? "py-3 opacity-100" : "h-0 py-0 opacity-0"}`}>
				<ul className="me-2 ms-6 flex flex-col gap-y-2 text-sm text-logo-purple">
					{citations.map((citation, index) => (
						<li key={index}>
							<span className="flex flex-row justify-between gap-x-2">
								{/* Truncated URL */}
								<a
									href={citation.sourceUrl}
									target="_blank"
									rel="noreferrer"
									className="min-w-32 truncate !no-underline hover:!underline">
									{citation.sourceUrl}
								</a>

								{/* Description */}
								{citation.description !== null && (
									<span className="text-nowrap text-right italic text-gray-400">
										{citation.description}
									</span>
								)}
							</span>
						</li>
					))}
				</ul>
			</div>
		</div>
	)
}

export default Citations
